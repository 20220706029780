













































import Base from '@/mixins/Base.vue';
import { IClient, IClientResponse } from '@/interfaces/client';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            client: undefined as any | IClient,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.client.name;
        },
    },
    mounted() {
        this.active = true;
        this.getClient();
    },
    methods: {
        getClient(): void {
            this.get<IClientResponse>(`clients/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.client = data.data;
                });
        },
        save(): void {
            if (this.validated) {
                this.delete(`clients/${this.client.id}`).then(() => {
                    this.$router.push({ name: 'clients.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
